import React from 'react';
import Image from 'gatsby-image';
import { graphql } from 'gatsby';
import Divider from '../components/divider';
import Grid from '@material-ui/core/Grid';
import Button from "@material-ui/core/Button";
import ApplicationPapers from '../assets/Application_Papers_08-2020.pdf';
import InterviewProcess from '../assets/Interview_Process_08-2020.pdf';
import ReferenceProcess from '../assets/Reference_Process_08-2020.pdf';
import SampleResume from '../assets/Sample_Resume_05-2020.pdf';
import InterviewQuestions from '../assets/Interview_Questions_05-2020.pdf';
import TipsForVirtualInterviewing from '../assets/Handout.VirtualInterviewing2021.pdf'
import Layout from '../components/layout';
import SEO from '../components/seo';
import '../components/css/candidates.css';

const CandidatesPage = ({data}) => {
  return (
    <Layout>
      <SEO title="Candidates" description="Questions and answers for prospective candidates, including application papers, sample resume, and interview questions." />
      <div className="candidates -container">
        <div className="candidates -header">
          <h1>Candidate Resources</h1>
          <Divider />
        </div>
        <div className="candidates -success">
          <Grid container spacing={2} alignItems="center">
            <Grid item sm={12} md={6}>
              <h2>Your Success Is Important to Us</h2>
              <p>While Pauly Group works for colleges and never for individuals,
              we know that many prospective candidates have questions about
              writing cover letters, CV layouts and interview techniques. We are
              providing this information to help you put your best foot forward
              whenever you decide to pursue new opportunities. We hope you find
              it helpful!</p>
              <div className="candidates -buttons">
                <a href={ApplicationPapers} target="_blank" rel="noopener noreferrer">
                  <Button className="candidates -button" aria-label="Sample Application Papers">APPLICATION PAPERS</Button>
                </a>
				<a href={InterviewProcess} target="_blank" rel="noopener noreferrer">
                  <Button className="candidates -button" aria-label="The Interview Process">THE INTERVIEW PROCESS</Button>
                </a>
				<a href={ReferenceProcess} target="_blank" rel="noopener noreferrer">
                  <Button className="candidates -button" aria-label="The Reference Process">THE REFERENCE PROCESS</Button>
                </a>
                <a href={SampleResume} target="_blank" rel="noopener noreferrer">
                  <Button className="candidates -button" aria-label="Sample Resume">SAMPLE RESUME</Button>
                </a>
                <a href={InterviewQuestions} target="_blank" rel="noopener noreferrer">
                  <Button className="candidates -button" aria-label="Sample Interview Questions">SAMPLE INTERVIEW QUESTIONS</Button>
                </a>
                <a href={TipsForVirtualInterviewing} target="_blank" rel="noopener noreferrer">
                  <Button className="candidates -button" aria-label="Sample Interview Questions">TIPS FOR VIRTUAL INTERVIEWING</Button>
                </a>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <Image fluid={data.candidatesImage.childImageSharp.fluid} />
            </Grid>
          </Grid>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    candidatesImage: file(relativePath: {eq: "Candidates-Image.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

export default CandidatesPage
